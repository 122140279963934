import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { fromLonLat, Projection } from "ol/proj";
import Geolocation from "ol/Geolocation";
import Draw from "ol/interaction/Draw";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import {
  OSM,
  Vector as VectorSource,
  ImageArcGISRest,
  ImageWMS,
} from "ol/source";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as LayerGroup,
  Image as ImageLayer,
  Layer,
} from "ol/layer";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";
import { XYZ, TileWMS } from "ol/source";
import Geocoder from "ol-geocoder";
import {
  MousePosition,
  defaults as defaultControls,
  ScaleLine,
} from "ol/control";
import { createStringXY } from "ol/coordinate";
import { get as getProjection, toLonLat } from "ol/proj";
import { getTopLeft, getWidth } from "ol/extent";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

const projection = getProjection("EPSG:4326");
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const resolutions = new Array(21);
const matrixIds = new Array(21);
for (let z = 0; z < 21; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions[z] = size / Math.pow(2, z + 1);
  matrixIds[z] = "EPSG:4326:" + z;
}

let currentLocation;
const baseURL = "http://haleandregimen.com/";
// const baseURL = "https://152.70.74.15:8080/"
const mapboxkey =
  "pk.eyJ1IjoiZ2lyaXNoMDYyOCIsImEiOiJjazJuZDUxdW8wNXRkM2lvNGRtcGRndTJkIn0.-X4sf_ipBJB97G5uNgvuQw";
const attributions =
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(10),
  projection: "EPSG:4326",

  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: "custom-mouse-position",
  target: document.getElementById("mouse-position"),
});

const measureSource = new VectorSource();

const measureVector = new VectorLayer({
  source: measureSource,
  style: new Style({
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
    stroke: new Stroke({
      color: "#ffcc33",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: "#ffcc33",
      }),
    }),
  }),
});

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
let sketch;

/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
let helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
let helpTooltip;

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
const continuePolygonMsg = "Click to continue drawing the polygon";

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
const continueLineMsg = "Click to continue drawing the line";

/**
 * Handle pointer move.
 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
 */
const pointerMoveHandler = function (evt) {
  if (evt.dragging) {
    return;
  }
  /** @type {string} */
  let helpMsg = "Click to start drawing";

  if (sketch) {
    const geom = sketch.getGeometry();
    if (geom instanceof Polygon) {
      helpMsg = continuePolygonMsg;
    } else if (geom instanceof LineString) {
      helpMsg = continueLineMsg;
    }
  }

  helpTooltipElement.innerHTML = helpMsg;
  helpTooltip.setPosition(evt.coordinate);

  helpTooltipElement.classList.remove("hidden");
};

const accuracyFeature = new Feature();
const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: "#3399CC",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2,
      }),
    }),
  })
);
const lt = new VectorLayer({
  source: new VectorSource({
    features: [accuracyFeature, positionFeature],
  }),
});

const view = new View({
  // center: fromLonLat([76.75852732111936, 30.988485681270635]),
  center: fromLonLat([77.524776, 23.863565]),
  // center: [0, 0],
  maxZoom: 23,
  zoom: 5,
  // projection: 'EPSG:4326'
});
const map = new Map({
  controls: defaultControls().extend([mousePositionControl]),
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    new TileLayer({
      source: new XYZ({
        attributions: "Copyright:© Mapbox Satellite",
        url:
          "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=" +
          mapboxkey,
      }),
      visible: false,
    }),
    // new TileLayer({
    //   source: new XYZ({
    //     attributions: 'Copyright:© 2013 ESRI, i-cubed, GeoEye',
    //     url:
    //       'https://services.arcgisonline.com/arcgis/rest/services/ESRI_Imagery_World_2D/MapServer/tile/{z}/{y}/{x}',
    //     maxZoom: 23,
    //     // projection: 'EPSG:4326',
    //     tileSize: 512, // the tile size supported by the ArcGIS tile service
    //     maxResolution: 180 / 512, // Esri's tile grid fits 180 degrees on one 512 px tile
    //     wrapX: true,
    //   }),
    //   visible: false
    // }),
    // new TileLayer({
    //   source: new XYZ({
    //     attributions:
    //       'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    //     url:
    //       'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    //   }),
    //   visible: false
    // }),
    // new TileLayer({
    //   source: new XYZ({
    //     attributions: ['Powered by Esri',
    //     'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
    //     url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    //     // maxZoom: 23
    //   }),
    //   visible: false
    // }),
    new LayerGroup({
      layers: [
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:BAGARH",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                76.34374131928529, 31.431953420392016, 76.35281164063863,
                31.44035902399899,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:BAROTIWALA",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                76.83000540911469, 30.911613461611786, 76.83210236539723,
                30.913220543170024,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:DEHA_SHIMLA",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.4537932132073, 31.042653457291376, 77.45728488355108,
                31.044038168314202,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:DHAULA_KAUN",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.46120029104614, 30.501276172807625, 77.46506993216352,
                30.505452135719676,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:HALE",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.53056089079028, 28.485802836021346, 77.53299765456697,
                28.488111423206306,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:MANPURA",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                76.7582060218343, 30.988305228992257, 76.76032481784803,
                30.989996189602362,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:KAITHU_SHIMLA",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.16010187867793, 31.1075035875641, 77.1647993165977,
                31.110793747399008,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:NAHAN",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.2960141430948, 30.556410460122112, 77.30007981018687,
                30.5581357974219,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:SOLAN_POLICE_LINE",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.08751667331326, 30.91315524564852, 77.09499993648348,
                30.91799542626105,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),

        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:TUTU",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.07952309544397, 31.10102088602635, 77.0843358636987,
                31.1045599651194,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),

        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:KUSHMTI_POLICE_LINE",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.1871930620052, 31.070539634855816, 77.19045984058387,
                31.072348848629204,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),
        new TileLayer({
          source: new WMTS({
            url: `${baseURL}geoserver/geo-aeuronatics/gwc/service/wmts`,
            layer: "geo-aeuronatics:itbp",
            matrixSet: "EPSG:4326",
            format: "image/png",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              tileSize: [256, 256],
              extent: [
                77.44869500118362, 28.507320318856763, 77.4572382402931,
                28.515843209743238,
              ],
              origin: [-180.0, 90.0],
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "",
            wrapX: true,
          }),
        }),

        new TileLayer({
          visible: true,
          source: new TileWMS({
            url: `${baseURL}geoserver/geo-aeuronatics/wms`,
            params: {
              FORMAT: "image/png",
              VERSION: "1.1.1",
              tiled: true,
              STYLES: "",
              LAYERS: "geo-aeuronatics:poi",
              exceptions: "application/vnd.ogc.se_inimage",
              //  tilesOrigin: 76.34777476982855 + "," + 30.912451419369766
            },
          }),
        }),
      ],
    }),
    measureVector,
    lt,
  ],
  target: "map",
  view: view,
});

///////////////
let locTrack = false;
const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
});
// geolocation.setTracking(true);
// update the HTML page when the position changes.
geolocation.on("change", function () {
  // console.log(geolocation)
  currentLocation = geolocation.getPosition();
  // geolocation.setTracking(locTrack);
  positionFeature.setGeometry(
    currentLocation ? new Point(currentLocation) : null
  );
});

// handle geolocation error.
geolocation.on("error", function (error) {
  // const info = document.getElementById('info');
  // info.innerHTML = error.message;
  // info.style.display = '';
});

geolocation.on("change:accuracyGeometry", function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

geolocation.on("change:position", function () {
  const coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
  view.setCenter(coordinates);
  view.setZoom(20);
});

/////////////////////

// popup
// var popup = new Overlay
// map.addOverlay(popup);

//Instantiate with some options and add the Control
var geocoder = new Geocoder("nominatim", {
  provider: "osm",
  lang: "en",
  placeholder: "Search for ...",
  limit: 5,
  debug: false,
  autoComplete: true,
  keepOpen: true,
  targetType: "text-input",
});
map.addControl(geocoder);

//Listen when an address is chosen
geocoder.on("addresschosen", function (evt) {
  console.info(evt);
  window.setTimeout(function () {
    // popup.show(evt.coordinate, evt.address.formatted);
    console.log(evt.coordinate, evt.address.formatted);
  }, 3000);
});

let draw; // global so we can remove it later

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line) {
  const length = getLength(line);
  let output;
  if (length > 1000) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};

function addInteraction(type = "LineString") {
  // const type = typeSelect.value == 'area' ? 'Polygon' : 'LineString';
  draw = new Draw({
    source: measureSource,
    type: type,
    style: new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        lineDash: [10, 10],
        width: 4,
      }),
      image: new CircleStyle({
        radius: 8,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)",
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
      }),
    }),
  });
  map.addInteraction(draw);

  createMeasureTooltip();
  createHelpTooltip();

  let listener;
  draw.on("drawstart", function (evt) {
    // set sketch
    sketch = evt.feature;

    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
    let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on("change", function (evt) {
      const geom = evt.target;
      let output;
      if (geom instanceof Polygon) {
        output = formatArea(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on("drawend", function () {
    measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
    measureTooltip.setOffset([0, -7]);
    // unset sketch
    sketch = null;
    // unset tooltip so that a new one can be created
    measureTooltipElement = null;
    createMeasureTooltip();
    unByKey(listener);
  });
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip() {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement("div");
  helpTooltipElement.className = "ol-tooltip hidden";
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: "center-left",
  });
  map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip() {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
    // stopEvent: false,
    // insertFirst: false,
  });
  map.addOverlay(measureTooltip);
}

let cur = false;

$("#currentLocation").click(() => {
  // console.log(map.getView().getCenter());
  // console.log(toLonLat( map.getView().getCenter()));
  // console.log(map.getView().calculateExtent());
  // console.log(map.getView().getZoom());

  // // let currentLocation = map.getView().getCenter();
  // let currentZoom = map.getView().getZoom()
  // console.log(locTrack);
  // if(locTrack){
  locTrack = !locTrack;
  geolocation.setTracking(locTrack);
  // }
  // if(cur){
  //   geolocation.setTracking(cur);
  // }else{
  //   geolocation.setTracking(!cur);
  // }

  // locTrack = !locTrack;
  // if(currentLocation){
  //   view.setCenter(currentLocation);
  //   view.setZoom(20);
  // }
  //    const coordinates = geolocation.getPosition();
  //   positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
  //   view.setCenter(coordinates);
  //   view.setZoom(20);
});
$("#zoomIn").click(() => {
  // view.setZoom(map.getView().getZoom() + 1)
  view.animate({
    zoom: view.getZoom() + 1,
    duration: 250,
  });
});

$("#zoomOut").click(() => {
  view.animate({
    zoom: view.getZoom() - 1,
    duration: 250,
  });
});
$("#zoomToCurrent").click(() => {
  geolocation.on("change:position", function () {
    const coordinates = geolocation.getPosition();
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    view.setCenter(coordinates);
    view.setZoom(18);
    geolocation.setTracking(false);
  });
  geolocation.setTracking(true);
});

$("#zoomToExtent").click(() => {
  // view.fit([4872985.7739096135, 1682572.2076593796, 12387051.40245558, 3791011.1958776815])
  view.setCenter(fromLonLat([77.524776, 23.863565]));
  view.setZoom(5);
});

$("#measureLength").click(() => {
  map.removeInteraction(draw);
  addInteraction("LineString"); //'Polygon' : 'LineString';
  map.on("pointermove", pointerMoveHandler);

  map.getViewport().addEventListener("mouseout", function () {
    helpTooltipElement.classList.add("hidden");
  });
});

$("#measureArea").click(() => {
  map.removeInteraction(draw);
  addInteraction("Polygon");
  map.on("pointermove", pointerMoveHandler);

  map.getViewport().addEventListener("mouseout", function () {
    helpTooltipElement.classList.add("hidden");
  });
});

$("#clear").click(() => {
  map.removeInteraction(draw);
  measureSource.clear();
  map.un("pointermove", pointerMoveHandler);
  createHelpTooltip();
  const element = document.getElementsByClassName(
    "ol-tooltip ol-tooltip-static"
  );

  document.querySelectorAll(".ol-tooltip-static").forEach((el) => el.remove());
  // for(let i = 0; element.length > i; i++){
  //   element[i].remove()
  // }
});

function bindInputs(layerid, layer) {
  const visibilityInput = $(layerid + " input.visible");
  visibilityInput.on("change", function () {
    layer.setVisible(true);
    // console.log(layer.getVisible())
  });
  visibilityInput.prop("checked", layer.getVisible());

  // const opacityInput = $(layerid + ' input.opacity');
  // opacityInput.on('input change', function () {
  //   layer.setOpacity(parseFloat(this.value));
  // });
  // opacityInput.val(String(layer.getOpacity()));
}
function setup(id, group) {
  // console.log(group)
  group.getLayers().forEach(function (layer, i) {
    const layerid = id + i;
    bindInputs(layerid, layer);
    if (layer instanceof LayerGroup) {
      setup(layerid, layer);
    }
  });
}
setup("#layer", map.getLayerGroup());

$("#visible20").on("change", function () {
  let visible = this.checked;
  map
    .getLayerGroup()
    .getLayers()
    .forEach(function (layer, i) {
      console.log(i);
      if (layer instanceof LayerGroup) {
        layer.setVisible(visible);
        const opacityInput = $("#layer20" + " input.opacity");
        opacityInput.on("input change", function () {
          layer.setOpacity(parseFloat(this.value));
        });
        opacityInput.val(String(layer.getOpacity()));
      }
    });
});
$("#visible213").on("change", function () {
  let visible = this.checked;
  map
    .getLayerGroup()
    .getLayers()
    .forEach(function (layer, i) {
      console.log(layer);
      if (layer instanceof LayerGroup) {
        layer.getLayersArray()[12].setVisible(visible);
      }
    });
});
$("#visible0").on("change", function () {
  let visible = this.checked;
  map
    .getLayerGroup()
    .getLayers()
    .forEach(function (layer, i) {
      if (layer instanceof LayerGroup === false && i === 0) {
        layer.setVisible(visible);
      }
    });
});
$("#visible1").on("change", function () {
  console.log(this.checked);
  let visible = this.checked;
  map
    .getLayerGroup()
    .getLayers()
    .forEach(function (layer, i) {
      if (layer instanceof LayerGroup === false && i === 1) {
        layer.setVisible(visible);
      }
    });
});

const createMap = () => {
  var queryString = document.location.href.toString();
  if (!queryString.includes("?")) return;
  var coordinate = queryString.split("?")[1].split("/");
  view.fit(coordinate[0].split`,`.map((x) => +x));
  view.setZoom(coordinate[1]);
};
createMap();

$("#wtsp").click(() => {
  let urlString = document.location.href.toString().replace("#", "");
  console.log(urlString);
  // console.log(map.getView().calculateExtent());
  // console.log(map.getView().getZoom());
  let mapExtent = map.getView().calculateExtent().toString();
  let currentZoom = map.getView().getZoom().toString();

  let shareUrl = `whatsapp://send?text=${urlString}?${mapExtent}/${currentZoom}`;

  // console.log(shareUrl)
  var win = window.open(shareUrl, "_blank");
  if (win) {
    //Browser has allowed it to be opened
    win.focus();
  } else {
    //Browser has blocked it
    alert("Please allow popups for this website");
  }
});

$("#visible20").trigger("change");

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  // console.error("Mobile");
}
